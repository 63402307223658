import ArchivedReceiptsItem from "./ArchivedReceiptsItem"

export default function ArchivedReceiptsList({ receipts }) {
    return (
        <>
            {receipts.slice(0, 10).map(receipt => (
                <ArchivedReceiptsItem receipt={receipt} />
            ))}
        </>
    )
}