import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { useDispatch } from "react-redux";
import { userApi } from "./services/userServices";

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (credentials, { dispatch }) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/users/log-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            const data = await response.json();

            console.log({ data })

            return data; // Return the token and any other necessary data
        } else {
            throw new Error('Login failed');
        }
    }
);

const initialState = {
    loadingLogin: false,
    errorLogin: false,
    userLogin: null,
    token: null,
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addToken(state, action) {
            state.token = action.payload
        },
        removeToken(state) {
            state.loadingLogin = false;
            state.errorLogin = false;
            state.userLogin = null;
            state.token = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                state.token = action.payload.token; 
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.error.message;
            });
    },
})

export const authActions = authSlice.actions
export default authSlice;