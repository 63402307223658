import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router"
import { menuActions } from "../store/menuSlice";
import AddUser from "./users/AddUser";
import AddNotification from "./notifications/AddNotification";

export default function AddItemComponent() {

    const { category } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuActions.changeActiveCategory({ category: category }))
    }, [dispatch, category])

    return (
        <>
            {category === 'users' && <AddUser />}
            {category === 'notifications' && <AddNotification />}
        </>
    )
}