import { LuTrash2 } from "react-icons/lu";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import { useNavigate } from "react-router";

export default function PrizesListItem({ prize }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <>
            <tr className="table_row">
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{prize.id}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">{prize.winner}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{prize.email}</td>
                <td style={tBodyItemStyle} className="w-[15%]">{prize.amount.toFixed(2)}€</td>
                {prize.payment_type === 'Basic' && <td style={tBodyItemStyle} className="w-[20%]">{prize.payment_type} Plan <span className="font-semibold">6.99€</span></td>}
                {prize.payment_type === 'Premium' && <td style={tBodyItemStyle} className="w-[20%]">{prize.payment_type} Plan <span className="font-semibold">16.99€</span></td>}
                {prize.payment_type === 'Unlimited' && <td style={tBodyItemStyle} className="w-[20%]">{prize.payment_type} Plan <span className="font-semibold">39.99€</span></td>}
                <td style={tBodyItemStyle} className="w-[10%]">{prize.date}</td>
                <td style={tBodyItemStyle} className="w-[20%]">
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions" onClick={() => dispatch(modalActions.openModal({ details: { id: prize.id, name: prize.full_name, category: 'prize' } }))}>
                            <LuTrash2 style={{ fontSize: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                    </div>
                </td>
            </tr >
        </>
    )
}