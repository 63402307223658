import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteUserMutation } from '../store/services/userServices';
import { selectedReceiptsActions } from '../store/selectedReceiptsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useCreatePropertyTypeMutation, useGetPropertyTypesQuery } from '../store/services/propertyTypeServices';
import { createItemModalActions } from '../store/createItemModalSlice';
import { useCreateSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useCreateHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useCreateFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useCreateCharacteristicMutation } from '../store/services/characteristicsServices';
import { receiptViewActions } from '../store/receiptViewSlice';
import { labelStyle } from './users/EditUser';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useGetArchivedReceiptByIdQuery, useGetSubmittedReceiptByIdQuery, useVerifyReceiptMutation } from '../store/services/receiptsServices';
import { archivedReceiptViewActions } from '../store/archivedReceiptViewSlice';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function ArchivedReceiptView() {

    const [verifyReceipt] = useVerifyReceiptMutation()

    const dispatch = useDispatch()
    const receipt = useSelector(state => state.archived_receipt_view)
    console.log({ receipt })
    const isOpen = receipt.isOpen
    const [approveConfirm, setApproveConfirm] = useState(false)
    const [rejectConfirm, setRejectConfirm] = useState(false)

    const { data } = useGetArchivedReceiptByIdQuery({ id: receipt.receipt.receipt_id })

    console.log({ data })

    function closeModal() {
        dispatch(archivedReceiptViewActions.closeModal())
        setApproveConfirm(false)
        setRejectConfirm(false)
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-2 px-4 w-[800px] h-[600px]">
                    <div className='flex gap-8 h-full'>
                        <div className='w-1/2 h-full max-h-full no-scrollbar flex justify-center items-center'>
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                    <>
                                        <TransformComponent>
                                            <div className='h-full w-full flex justify-center items-center'>
                                                <img src={`${process.env.REACT_APP_BACKEND_HOST}/api/${data?.image_path}`} alt="img" className="w-[85%] h-full object-cover" />
                                            </div>
                                        </TransformComponent>
                                        {/* <Controls /> */}
                                    </>
                                )}
                            </TransformWrapper>
                        </div>
                        <div className='h-full flex flex-col justify-between w-1/2 text-sm'>
                            <div>
                                <label className={labelStyle} htmlFor="vat_number">Numri fiskal</label>
                                <input type="tel" id="vat_number" name="vat_number" value={data?.vat_number} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="company">Emri i kompanise</label>
                                <input type="text" id="company" name="company" value={data?.business_name} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="address">Adresa</label>
                                <input type="text" id="address" name="address" value={data?.address} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="total_without_vat">Shuma pa TVSH</label>
                                <input type="text" id="total_without_vat" name="total_without_vat" value={data?.total_without_vat} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="vat_amount">TVSH</label>
                                <input type="tel" id="vat_amount" name="vat_amount" value={data?.vat_amount} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="total_amount">Shuma totale</label>
                                <input type="tel" id="total_amount" name="total_amount" value={data?.total_amount} className={`block rounded-md border border-gray-300 w-full p-4 mt-2 disabled:bg-white`} disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
}