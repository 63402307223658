import { RiCloseLargeFill } from "react-icons/ri";
import { MdDone } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../store/alertSlice";
import { useEffect, useState } from "react";

export default function Alert() {

    const dispatch = useDispatch()
    const alert = useSelector(state => state.alert)
    const [position, setPosition] = useState('absolute')

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setPosition('sticky');
            } else {
                setPosition('absolute');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup event listener on unmount
        };
    }, []);

    return (
        <div className={`w-[90%] sm:w-auto ${alert.type === 'error' ? 'bg-rose-600 text-center   text-white border border-rose-600' : 'bg-emerald-500 text-white border border-emerald-500'} z-10 left-1/2 -translate-x-1/2 my-6 transition-all fixed p-3 shadow-md rounded-full flex gap-4 px-8 items-center ${alert.isOpen ? 'translate-y-0' : '-translate-y-40'}`}>
            <div className="flex gap-4 items-center">
                <span className={`w-6 h-6 flex items-center justify-center rounded-full ${alert.type === 'error' ? 'bg-white text-rose-600' : 'bg-white text-emerald-500'}`}>
                    {alert.type === 'success' && <MdDone fontSize={18} />}
                    {alert.type === 'error' && <MdClose fontSize={18} />}
                </span>
                <span>{alert.text}</span>
            </div>
            <span className="cursor-pointer" onClick={() => dispatch(alertActions.closeModal())}><RiCloseLargeFill /></span>
        </div>
    )
}