import { createSlice } from "@reduxjs/toolkit";

const initialSelectedReceiptsState = { receipts: [], ids: [], all: false }

const selectedReceiptsSlice = createSlice({
    name: 'selectedReceipts',
    initialState: initialSelectedReceiptsState,
    reducers: {
        addOrRemoveReceipt(state, action) {
            const receipt = action.payload.receipt
            if (state.receipts.some(selected => selected.receipt_id === receipt.receipt_id)) {
                console.log('armir')
                const updatedSelectedReceipts = [...state.receipts]
                const updatedIds = [...state.ids]
                const filteredSelectedReceipts = updatedSelectedReceipts.filter(list => list.receipt_id !== receipt.receipt_id)
                const filteredIds = updatedIds.filter(id => id !== receipt.receipt_id)
                state.receipts = filteredSelectedReceipts
                state.ids = filteredIds
                state.all = false
                return;
            }
            state.receipts.push(receipt)
            state.ids.push(receipt.receipt_id)
        },
        clearSelectedReceipts(state) {
            state.receipts = []
            state.ids = []
            state.all = false
        },
        selectAllReceipts(state, action) {
            const receipts = action.payload.receipts
            // state.receipts.filter(receipt => receipts.map(list => list.id === receipt.id))
            state.receipts = receipts
            state.ids = receipts.map(receipt => receipt.id)
            state.all = true
        }
    }
})


export const selectedReceiptsActions = selectedReceiptsSlice.actions;
export default selectedReceiptsSlice