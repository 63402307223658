import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { menuActions } from "../store/menuSlice";
import EditBlog from "./notifications/EditBlog";
import EditReceipt from "./receipts/EditReceipt";
import EditUser from "./users/EditUser";

export default function EditItemComponent() {
    const { category } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuActions.changeActiveCategory({ category: category }))
    }, [dispatch, category])

    return (
        <>
            {category === 'users' && <EditUser />}
            {category === 'submitted_receipts' && <EditReceipt />}
            {category === 'blogs' && <EditBlog />}
        </>
    )
}