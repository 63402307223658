import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../../utils/axiosBaseQuery';

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
    }),
    endpoints: (build) => ({
        getNotifications: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/admin/notifications?${queryParams}`,
                    method: 'GET'
                }
            },
            providesTags: ['Notifications']
        }),
        sendNotification: build.mutation({
            query(data) {
                console.log({ data })
                return {
                    url: '/notifications/send-notification',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    method: 'POST',
                    data: data,
                    formData: true
                }
            },
            invalidatesTags: ['Notifications']
        }),
        searchUsers: build.query({
            query(data) {
                return {
                    url: `/admin/notifications/users?q=${data}`,
                    method: 'GET',
                }
            }
        })
    }),
});

export const { useGetNotificationsQuery, useSendNotificationMutation, useSearchUsersQuery, useLazySearchUsersQuery } = notificationApi;