import { FiEdit } from "react-icons/fi";
import { LuTrash2 } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { modalActions } from "../../store/modalSlice";
import { userActions } from "../../store/userSlice";
import { GoVerified } from "react-icons/go";
import { MdOutlineVerified } from "react-icons/md";

export const tBodyItemStyle = {
    padding: '12px 20px',
    borderBottom: '1px solid #eee',
}

export const tBodyRowStyle = {
    height: '72px',
    cursor: 'pointer'
}

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function UserListItem({ user }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleRowClick = () => {
        navigate(`/users/${user.id}/edit`)
        dispatch(userActions.fillUser({ user: user }))
    }

    const handleUserEditClick = (e) => {
        // e.stopPropagation();
        // dispatch(userActions.fillUser({ user: user }))
    }

    const handleUserDeleteClick = (e) => {
        e.stopPropagation();
        dispatch(modalActions.openModal(
            {
                details:
                {
                    id: user.id,
                    name: user.user_type === 'individual' ? `${user.first_name} ${user.last_name}` : user.company_name,
                    category: 'user'
                }
            }))
    }

    return (
        <>
            <tr className="table_row" onClick={handleRowClick}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">
                    <div className="flex gap-2 items-center">
                        {user.name}
                        {user.isVerified === 1 && <MdOutlineVerified color="var(--primary-color)" fontSize={20} title="Verified" />}
                    </div>
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{new Date(user.created_at).toLocaleString('en-GB')}</td>
                <td style={tBodyItemStyle} className="w-[15%]">{user.phone_number}</td>
                <td style={tBodyItemStyle} className="w-[20%]">{user.email}</td>
                <td style={tBodyItemStyle} className="w-[10%]">{capitalizeFirstLetter(user.role)}</td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer flex justify-center actions edit-action" title="Edit" onClick={handleUserEditClick}>
                            <FiEdit style={{ fontSize: '14px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions delete-action" title="Delete" onClick={handleUserDeleteClick}>
                            <LuTrash2 style={{ fontSize: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}