import './App.css';
import Wrapper from './components/Wrapper';
import Menu from './components/Menu';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import CategoryComponent from './components/CategoryComponent';
import AddItemComponent from './components/AddItemComponent';
import ArchivedComponent from './components/ArchivedComponent';
import DeleteModal from './components/DeleteModal';
import { useSelector } from 'react-redux';
import EditItemComponent from './components/EditItemComponent';
import Login from './Auth';
import Alert from './components/Alert';
import ReactModal from './components/ReactModal';
import AccessoriesModal from './components/CharacteristicsModal';
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from 'react';
import BrandSelectModal from './components/BrandSelectModal';
import Homepage from './components/Homepage';

function App() {

  const isModalOpen = useSelector(state => state.modal.isOpen)
  const isAlertOpen = useSelector(state => state.alert.isOpen)
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const token = localStorage.getItem('giz_superaccess')
  const [isAdmin, setIsAdmin] = useState(false)
  const navigate = useNavigate()
  const isBrandSelectModalOpen = useSelector(state => state.accessoryBrandSelect.isOpen)

  useEffect(() => {
    if (token) {
      const user = jwtDecode(token);
      if (user.role === 'admin') {
        setIsAdmin(true)
      }
    }
  }, [token])

  return (
    <Wrapper>
      <Alert />
      {/* <ReactModal /> */}
      {isBrandSelectModalOpen && <BrandSelectModal />}
      {/* {!isLoginPage && isModalOpen && <DeleteModal />} */}
      {isLoginPage ? (
        token && isAdmin ? (
          <Navigate to="/" replace />
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
          </Routes>
        )) : token && isAdmin ? (
          <div className="flex relative">
            <div className="h-screen p-4 sticky top-0">
              <Menu />
            </div>
            <div className="flex-grow bg-white py-4 pr-4 min-h-screen">
              <div className="w-full h-full border border-gray-200 rounded-xl relative overflow-hidden">
                <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/:category" element={<CategoryComponent />} />
                  <Route path="/:category/add" element={<AddItemComponent />} />
                  <Route path="/:category/archived" element={<ArchivedComponent />} />
                  <Route path="/:category/:id/edit" element={<EditItemComponent />} />
                  {/* Add a catch-all route for 404 here if needed */}
                </Routes>
              </div>
            </div>
          </div>
        ) : (
        <Routes>
          <Route path="*" element={!token && !isAdmin && <Navigate to="/login" />} />
        </Routes>
      )}
    </Wrapper>
  );
}

export default App;
