import { useParams } from "react-router"
import ArchivedPayments from "./prizes/ArchivedPayments";
import { useEffect } from "react";
import { menuActions } from "../store/menuSlice";
import { useDispatch } from "react-redux";
import ArchivedReceipts from "./receipts/ArchivedReceipts";

export default function ArchivedComponent() {

    const { category } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuActions.changeActiveCategory({ category: category }))
    }, [dispatch, category])

    return (
        <>
            {category === 'receipts' && <ArchivedReceipts />}
            {category === 'payments' && <ArchivedPayments />}
        </>
    )
}