import { FiUsers } from "react-icons/fi";
import { IoRibbonOutline } from "react-icons/io5";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoNotificationsOutline } from "react-icons/io5";

export default function Homepage() {

    const itemStyle = 'flex flex items-center gap-8 overflow-hidden justify-center w-full h-full bg-white rounded-xl p-8 border shadow-lg hover:scale-[1.02] cursor-pointer transition-all'
    const iconStyle = 'w-28 h-28 flex items-center text-[#D1AB66] justify-center border border-[#D1AB66] rounded-full'

    return (
        <div className="p-16 h-full">
            <div className="w-full h-full grid grid-cols-3 gap-8">
                <div className={itemStyle}>
                    <div className={iconStyle}>
                        <IoDocumentTextOutline size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">8282</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts submitted</div>
                    </div>
                </div>
                <div className={itemStyle}>
                    <div className={iconStyle}>
                        <IoDocumentTextOutline size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">4021</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts approved</div>
                    </div>
                </div>
                <div className={itemStyle}>
                    <div className={iconStyle}>
                        <FiUsers size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">2189</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts rejected</div>
                    </div>
                </div>
                <div className={`${itemStyle} col-span-2 row-span-2`}>
                    <div className={iconStyle}>
                        <IoDocumentTextOutline size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">2371</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts submitted in the last month</div>
                    </div>
                </div>
                <div className={itemStyle}>
                    <div className={iconStyle}>
                        <IoRibbonOutline size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">295</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Prizes awarded</div>
                    </div>
                </div>
                <div className={itemStyle}>
                    <div className={iconStyle}>
                        <IoDocumentTextOutline size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">5270</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Users registered</div>
                    </div>
                </div>
            </div>
        </div >
    )
}