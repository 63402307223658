import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const accessoryApi = createApi({
    reducerPath: 'accessoryApi',
    baseQuery: (fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api` })),
    endpoints: (build) => ({
        getAccessories: build.query({
            query() {
                return {
                    url: '/accessories/getAllAccessories',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Accessories']
        }),
        getAccessoriesPerBrand: build.query({
            query({queryVehicleIdentifier: brandId, params: params}) {
                console.log({ brandId })
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/receipts/accessoriesPerBrand/${brandId}?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            }
        }),
        getAccessoryById: build.query({
            query(id) {
                return {
                    url: `/accessories/getAccessoryById/${id}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
        }),
        deleteAccessory: build.mutation({
            query(id) {
                return {
                    url: `/accessories/deleteAccessory/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Accessories']
        }),
    }),
});

export const { useGetAccessoriesQuery, useGetAccessoriesPerBrandQuery, useGetAccessoryByIdQuery, useDeleteAccessoryMutation } = accessoryApi;