import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const subPropertyTypeApi = createApi({
    reducerPath: 'subPropertyTypeApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin/sub_property_types`, prepareHeaders: (headers) => {
            const token = localStorage.getItem('giz_superaccess');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getSubPropertyTypes: build.query({
            query() {
                return {
                    url: '/getAll',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Sub Property Types']
        }),
        createSubPropertyType: build.mutation({
            query: (data) => ({
                url: '/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Sub Property Types']
        }),
        deleteSubPropertyType: build.mutation({
            query(id) {
                return {
                    url: `/delete/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Sub Property Types']
        }),
        updateSubPropertyType: build.mutation({
            query({ id, data }) {
                console.log({ data })
                return {
                    url: `/update/${id}`,
                    method: 'PUT',
                    body: data
                }
            },
            invalidatesTags: ['Sub Property Types']
        }),
    }),
});

export const {
    useLazyGetSubPropertyTypesQuery,
    useGetSubPropertyTypesQuery,
    useCreateSubPropertyTypeMutation,
    useDeleteSubPropertyTypeMutation,
    useUpdateSubPropertyTypeMutation
} = subPropertyTypeApi;