import { Checkbox } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectedReceiptsActions } from "../store/selectedReceiptsSlice"

export default function ReceiptDashboardTable({ tableData }) {

    const { headLabels, list, data } = tableData

    const selectedReceipts = useSelector(state => state.selectedReceipts)
    const dispatch = useDispatch()

    const tHeadStyle = {
        padding: '10px 20px',
        fontSize: '12px',
        fontWeight: '500',
        color: '#595959',
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            dispatch(selectedReceiptsActions.selectAllReceipts({ receipts: data }))
        }
        else {
            dispatch(selectedReceiptsActions.clearSelectedReceipts())
        }
    }

    return (
        <table className="w-full text-[14px] text-[#595959]">
            <thead>
                <tr className="bg-[#F9FAFB] border-b border-[#eee]">
                    {headLabels.map(label => (
                        <td key={label.id} style={tHeadStyle} className="text-nowrap">{label}</td>
                    ))}
                    {!headLabels.includes('Plan Ended') && <td style={tHeadStyle} className="text-center">Actions</td>}
                </tr>
            </thead>
            {data && (
                <tbody className="[&>*:nth-child(even)]:bg-[#F9FAFB]">
                    {list}
                </tbody>
            )}
        </table>
    )
}