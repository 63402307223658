import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import DashboardTable from "../DashboardTable"
import BlogList from "./NotificationsList"
import { BiPlus } from "react-icons/bi"
import { useEffect, useState } from "react"
import { useGetBlogsQuery } from "../../store/services/blogServices"
import ReactModal from "../ReactModal"
import NotificationsList from "./NotificationsList"
import { useGetNotificationsQuery } from "../../store/services/notificationServices"

const notifications = [
    {
        id: 1,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 2,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 3,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 4,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 5,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 6,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        user: 'Filan Fisteku',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 7,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 8,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 9,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 10,
        title: 'Fatura juaj eshte verifikuar',
        datetime: '2/12/2024 12:21 PM',
        text: 'Me kenaqesi ju njoftojme se fatura juaj eshte verifikuar',
        user: 'Filan Fisteku',
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
]

export default function NotificationsMain() {

    const { data, isLoading, isError, isSuccess } = useGetNotificationsQuery()
    const [showFilters, setShowFilters] = useState(true)
    const [filterSelected, setFilterSelected] = useState("")

    // if (isLoading) {
    //     return <div>Loading...</div>
    // }

    // if (isError) {
    //     return <div>Couldn't fetch notifications</div>
    // }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const buttons = [
        { label: 'Add notification', color: 'bg-primary text-white', border: 'border border-b-2 border-primary', icon: <BiPlus />, shadow: 'shadow-md', onClick: null, linkTo: "/notifications/add" }
    ]

    const details = {
        title: 'Notifications',
        count: data?.length
    }

    const tableData = {
        headLabels: ['Title', 'Text', 'Time', 'Image'],
        list: <NotificationsList notifications={data} />,
        data: data
    }

    const filters = {
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: null,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    return (
        <>
            <ReactModal />
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <DashboardFilters filters={filters} />
            <div className="w-full">
                <DashboardTable tableData={tableData} />
            </div>
            <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div>
        </>
    )
}