import { FiEdit } from "react-icons/fi";
import { LuTrash2 } from "react-icons/lu";
import { IoPlayOutline } from "react-icons/io5";
import { MdDone } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
// import { AiOutlinePause } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import { AiOutlinePause } from "react-icons/ai";
import { openModalWithTimeout } from "../../store/alertSlice";
import Checkbox from '@mui/material/Checkbox';
import { selectedReceiptsActions } from "../../store/selectedReceiptsSlice";
import { CiImageOff } from "react-icons/ci";
import { useVerifyReceiptMutation } from "../../store/services/receiptsServices";
import { receiptViewActions } from "../../store/receiptViewSlice";

export const getStatusClasses = (status) => {
    if (status) {
        switch (status.toLowerCase()) {
            case 'approved':
                return 'bg-green-50 text-[#067647] border border-[#ABEFC6]';
            case 'pending':
                return 'bg-[#FDFCE6] text-[#979550] border border-[#979550]';
            case 'flagged':
                return 'bg-red-50 text-red-700 border border-red-300';
            case 'draft':
                return 'bg-gray-200 text-gray-700 border border-gray-300';
            case 'updated':
                return 'bg-sky-100 text-sky-600 border border-sky-300';
            default:
                return 'bg-gray-100 text-gray-700 border border-gray-300';
        }
    }
};

export default function ReceiptsListItem({ receipt, isSelected }) {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [verifyReceipt] = useVerifyReceiptMutation()

    function convertToLocaleDateString(date) {
        return new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit'
        });
    }

    function capitalizeFirstLetter(string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    //test
    const handlePauseStatus = async (e) => {
        e.stopPropagation();
        const pause_on = !receipt.pause_on
        try {
            // const response = await changePauseStatus({ id: receipt.receipt_id, data: { pause_on: pause_on } }).unwrap()
            // console.log("Pause status changed:", response)
            // dispatch(openModalWithTimeout({ text: 'Pause status changed successfully', type: 'success' }, 2000))
        }
        catch (error) {
            if (error.status === 403) {
                dispatch(openModalWithTimeout({ text: "Unauthorized: You do not have permission to delete this receipt", type: 'error' }, 2000))
            }
            else {
                dispatch(openModalWithTimeout({ text: "Failed to change pause status", type: 'error' }, 2000))
            }
            console.log(error)
        }
    }

    const handleApproveReceipt = async (e) => {
        console.log('armir')
        e.stopPropagation()
        const data = { value: 'approve' }
        try {
            const response = await verifyReceipt({ id: receipt.receipt_id, data: data }).unwrap()
            console.log("Receipt status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Receipt status changed successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change receipt status', type: 'error' }, 2000))
        }
    }

    const handleRejectReceipt = async (e) => {
        e.stopPropagation()
        const data = { value: 'reject' }
        try {
            const response = await verifyReceipt({ id: receipt.receipt_id, data: data }).unwrap()
            console.log("Receipt status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Receipt status changed successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change receipt status', type: 'error' }, 3000))
        }
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation()
        dispatch(modalActions.openModal({ details: { id: receipt.receipt_id, name: receipt.title, category: 'receipt' } }))
    }

    const handleReceiptSelect = (e) => {
        e.stopPropagation();
        dispatch(selectedReceiptsActions.addOrRemoveReceipt({ receipt }))
    }

    return (
        <>
            <tr className={`table_row ${isSelected ? '!bg-blue-50' : ''}`} onClick={() => dispatch(receiptViewActions.openModal(receipt))}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{receipt.receipt_id}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{convertToLocaleDateString(receipt.created_at)}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">
                    {receipt.vat_number}
                </td>
                <td style={{ ...tBodyItemStyle, padding: '0px 20px', }} className="text-black font-medium w-[15%]">
                    {receipt.name}
                    {/* <div className="rounded-xl w-[100px] h-[50px]">
                        {mainImage ? (
                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/${mainImage.path}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                        ) : (
                            receipt.images[0]?.path ? (
                                <img src={`${process.env.REACT_APP_BACKEND_HOST}/${receipt.images[0].path}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                            ) : (
                                <div className="border border-gray-300 rounded-xl object-cover h-full w-full flex items-center justify-center text-xs text-gray-400">
                                    <CiImageOff size={24} />
                                </div>
                            )
                        )}
                    </div> */}
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%] overflow-hidden">
                    {receipt.business_name}
                </td>
                <td style={tBodyItemStyle} className="w-[10%]">{receipt.vat_amount}€</td>
                <td style={tBodyItemStyle} className="w-[10%]">{receipt.total_amount}€</td>
                <td style={tBodyItemStyle} className="w-[5%]">
                    <span
                        className={`flex justify-center gap-1 items-center px-2 rounded-full p-[2px] text-xs font-semibold ${getStatusClasses(receipt.status)}`}
                        style={{ width: '100px' }}
                    >
                        <GoDotFill /> <span>{capitalizeFirstLetter(receipt.status)}</span>
                    </span>
                </td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    <div className="flex justify-center items-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions edit-action" title="Edit">
                            <FiEdit style={{ fontSize: '14px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                    </div>
                </td>
                {/* <td style={tBodyItemStyle} className="">
                    <div className="text-center" onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            size="small"
                            // sx={{
                            //     color: 'var(--primary-color)',
                            //     '&.Mui-checked': {
                            //         color: 'var(--primary-color)',
                            //     },
                            // }}
                            checked={isSelected}
                            onChange={handleReceiptSelect}
                        />
                    </div>
                </td> */}
            </tr>
        </>
    )
}