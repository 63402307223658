import { BiPlus } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import UserList from "./UserList";
import DashboardHeader from "../DashboardHeader";
import DashboardTable from "../DashboardTable";
import DashboardFilters from "../DashboardFilters";
import { useGetAllUsersQuery, useGetUsersQuery } from "../../store/services/userServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination";
import CircularProgress from '@mui/material/CircularProgress';
import ReactModal from '../ReactModal'

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

export default function UserDashboard() {

    // const { data, isLoading, isError, isSuccess } = useGetUsersQuery();

    const [filteredUsers, setFilteredUsers] = useState([
        {
            id: '',
            name: '',
            created_at: '',
            phone_number: '',
            email: '',
            role_type: '',
            user_type: '',
        },
    ])

    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)
    const navigate = useNavigate()
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const [allUsersData, setAllUsersData] = useState([])
    const [searchTextInput, setSearchTextInput] = useState('')

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    const { data, isLoading, isError, isSuccess } = useGetUsersQuery(params);

    useEffect(() => {
        if (data) {
            console.log({ data })
            setFilteredUsers(data?.data)
            setPages({ currentPage: data.currentPage, totalPages: data.totalPages, totalCount: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("user_type")) {
            setFilterSelected(query.get("user_type"))
        }
        if (query.has("q")) {
            setSearchTextInput(query.get("q"))
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/users?${urlParams}`)
    }, [params, navigate])

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setPages({ ...pages, currentPage: page })
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
        setParams({ ...params, user_type: e.target.id, page: 1 })
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['user_type'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const debounceTimeoutRef = useRef(null);

    const handleSearchTextInputChange = (e) => {
        const text = e.target.value
        setSearchTextInput(text)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        if (text.length > 2) {
            debounceTimeoutRef.current = setTimeout(() => {
                setParams({ ...params, q: text })
            }, 500)
        }
        else {
            const updatedParams = { ...params }
            delete updatedParams['q']
            setParams(updatedParams)
        }
    }

    useEffect(() => {
        // if (!filterSelected.length) {
        //     setFilteredUsers(users)
        //     return;
        // }
        // setFilteredUsers(filteredUsers.filter(user => user.userType === filterSelected))
    }, [filterSelected])

    const buttons = [
        { label: 'Add user', color: 'bg-primary text-white', border: 'border border-b-2 border-primary', icon: <BiPlus />, shadow: 'shadow-md', onClick: null, linkTo: '/users/add' }
    ]

    const details = {
        title: 'Users',
        count: data?.totalCount
    }

    const tableData = {
        headLabels: ['Name & Surname', 'Created Date', 'Phone number', 'Email', 'Role'],
        list: <UserList users={filteredUsers} />,
        data: filteredUsers
    }

    const filters = {
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        filterByCityOptions: options,
        searchTextInput: searchTextInput,
        handleSearchTextInputChange: handleSearchTextInputChange
    }

    if (isLoading) return (
        <div className="w-full h-screen flex items-center justify-center text-primary"><CircularProgress color="var(--primary-color)" /></div>
    )

    return (
        <>
            <ReactModal />
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <DashboardFilters filters={filters} />
            <div className="w-full">
                <DashboardTable tableData={tableData} />
            </div>
            <Pagination currentPage={currentPage} totalPages={pages?.totalPages} onPageChange={handlePageChange} />
            {/* <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div> */}
        </>
    )
}