import NotificationsListItem from "./NotificationsListItem"

export default function NotificationsList({ notifications }) {
    return (
        <>
            {notifications.map(notification => (
                <NotificationsListItem notification={notification} />
            ))}
        </>
    )
}