import { useNavigate, useParams } from 'react-router-dom';
import UserDashboard from './users/UserDashboard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { menuActions } from '../store/menuSlice';
import ReceiptDashboard from './receipts/ReceiptsMain';
import HeatingTypesDashboard from './heating_types/HeatingTypesDashboard';
import FurnishingTypesDashboard from './furnishing_types/FurnishingTypesDashboard';
import CharacteristicsDashboard from './characteristics/CharacteristicsDashboard';
import LocationsDashboard from './locations/LocationsDashboard';
import NotificationsMain from './notifications/NotificationsMain';
import AnalyticsMain from './analytics/AnalyticsMain';
import PerformanceMain from './sub_property_types/PerformanceMain';
import PrizesMain from './prizes/PrizesMain';
import ArchivedReceipts from './receipts/ArchivedReceipts';

export default function CategoryComponent() {
  const { category } = useParams(); // category will be 'users' for '/users'

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!category) {
      navigate('/users')
      dispatch(menuActions.changeActiveCategory({ category: 'users' }))
      return;
    }
    dispatch(menuActions.changeActiveCategory({ category: category }))
  }, [dispatch, category, navigate])

  return (
    <>
      {category === 'users' && <UserDashboard />}
      {category === 'submitted_receipts' && <ReceiptDashboard />}
      {category === 'archived_receipts' && <ArchivedReceipts />}
      {category === 'prizes' && <PrizesMain />}
      {category === 'notifications' && <NotificationsMain />}
      {category === 'analytics' && <AnalyticsMain />}
      {category === 'performance' && <PerformanceMain />}
    </>
  );
}