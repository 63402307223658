import { createSlice } from "@reduxjs/toolkit";

const initialReceiptViewState = { receipt: {}, isOpen: false }

const receiptViewSlice = createSlice({
    name: 'receipt_view',
    initialState: initialReceiptViewState,
    reducers: {
        openModal(state, action) {
            state.receipt = action.payload
            state.isOpen = true
        },
        closeModal(state, action) {
            state.receipt = {}
            state.isOpen = false
        }
    }
})

export const receiptViewActions = receiptViewSlice.actions;
export default receiptViewSlice