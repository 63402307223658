import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const receiptsApi = createApi({
    reducerPath: 'receiptsApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin`,
    }),
    endpoints: (build) => ({
        getReceipts: build.query({
            query({ params }) {
                const newParams = { ...params, status: 'submitted' }
                console.log({ newParams })
                const queryParams = new URLSearchParams(newParams).toString()

                return {
                    url: `/receipts?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Submitted Receipts']
        }),
        getSubmittedReceiptById: build.query({
            query({ id }) {
                return {
                    url: `/receipts/${id}?status=submitted`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Submitted Receipt']
        }),
        getArchivedReceipts: build.query({
            query(params) {
                params = { ...params, status: 'archived' }
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/receipts?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Archived Receipts']
        }),
        getArchivedReceiptById: build.query({
            query({ id }) {
                console.log({ id })
                return {
                    url: `/receipts/${id}?status=archived`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Archived Receipt']
        }),
        deleteArchivedReceipt: build.mutation({
            query(id) {
                return {
                    url: `/archived-receipts/${id}`,
                    method: 'PATCH',
                }
            },
            invalidatesTags: ['Archived Receipts']
        }),
        verifyReceipt: build.mutation({
            query({ id, data }) {
                return {
                    url: `/receipts/verify/${id}`,
                    method: 'PATCH',
                    data: data
                }
            },
            invalidatesTags: ['Submitted Receipts', 'Archived Receipts']
        }),
        getReceiptById: build.query({
            query({ id, data }) {
                return {
                    url: `/${id}`,
                    method: 'GET',
                }
            },
            invalidatesTags: ['Submitted Receipts', 'Archived Receipts']
        })
    }),
});

export const {
    useGetReceiptsQuery,
    useGetSubmittedReceiptByIdQuery,
    useGetArchivedReceiptsQuery,
    useGetArchivedReceiptByIdQuery,
    useDeleteArchivedReceiptMutation,
    useVerifyReceiptMutation,
    useGetReceiptByIdQuery
} = receiptsApi;    