import React, { useEffect, useState } from 'react';
import { authActions, loginUser } from './store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { userApi } from './store/services/userServices';
import { jwtDecode } from 'jwt-decode';
import { alertActions } from './store/alertSlice';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = useSelector(state => state.auth.token)

  const handleSubmit = (e) => {
    e.preventDefault();
    const credentials = {
      email,
      password,
    };
    dispatch(loginUser(credentials))
      .unwrap()
      .then((response) => {
        console.log("jwt", jwtDecode(response.access_token))
        // don't save the token in the local storage if user is not admin
        if (jwtDecode(response.access_token).role !== 'admin') {
          dispatch(alertActions.openModal({ text: "You don't have permission to access the dashboard", type: 'error' }))
          return;
        }
        localStorage.setItem('giz_superaccess', response.access_token);
        localStorage.setItem('giz_superrefresh', response.refresh_token);
        navigate('/')
      })
      .catch((error) => {
        console.error('Login failed:', error.message);
        setError("Wrong credentials");
      });
  };

  return (
    <div>
      <div className="absolute top-0 left-0 w-full min-h-screen flex items-center justify-center">
        <div className="max-w-md w-full p-6">
          <div className='flex justify-center mb-8'>
            <img src="/pics/atk-long.svg" alt="img" className='w-60' />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border rounded-md"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full p-2 border rounded-md"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className='text-center mb-3 text-red-500'>{error}</p>}
            <button
              type="submit"
              className="w-full bg-primary font-bold p-2 rounded-md text-white"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login