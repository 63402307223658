import { FiSearch } from "react-icons/fi";
import { BsFilter } from "react-icons/bs";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { MdOutlineDelete, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modalSlice";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function DashboardFilters({ filters }) {

    const selectedReceipts = useSelector(state => state.selectedReceipts)
    const dispatch = useDispatch()

    const [isMouseDown, setIsMouseDown] = useState(false)
    const [isDropDownActive, setIsDropDownActive] = useState(false)

    // const calendarRef = useRef(null);

    // const handleClickOutside = (event) => {
    //     // Check if the clicked target is outside the dropdown element
    //     if (filters.isDateRangeOpen) {
    //         if (calendarRef?.current && !calendarRef?.current.contains(event.target)) {
    //             filters.handleIsDateRangeOpen(true)
    //         }
    //     }
    // };

    // useEffect(() => {
    //     // Add event listener to the entire document
    //     document.addEventListener('mousedown', handleClickOutside);

    //     // Clean up event listener on component unmount
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    const handleApprovedClick = (e) => {
        filters.handleFilterSelect(e);
    }

    const handleDeleteAllReceipts = () => {
        dispatch(modalActions.openModal({ details: { category: 'receipts', name: `${selectedReceipts.receipts.length} listime` } }))
    }

    const handleDropdown = (e) => {
        e.stopPropagation();
        setIsDropDownActive(!isDropDownActive)
    }

    return (
        <div className="py-3 px-4">
            <div className="flex justify-between items-center gap-4">
                <div className="flex gap-4 items-center">
                    <div className="relative w-[300px] max-w-[300px]">
                        <input type="text" placeholder="Search" className="w-full border-b border-gray-300 p-2 pl-8 outline-none" value={filters.searchTextInput} onChange={filters.handleSearchTextInputChange} />
                        <FiSearch style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 0, fontSize: '20px', color: 'gray', fontWeight: '1.2rem' }} />
                    </div>
                    <div className={`flex h-8 gap-1 items-center border border-b-2 font-semibold p-2 px-4 text-gray-600 rounded-[12px] cursor-pointer ${filters.showFilters ? 'border-gray-200' : 'border-gray-400'} ${isMouseDown ? 'scale-95' : 'scale-100'}`} onClick={filters.handleShowFilters} onMouseDown={() => setIsMouseDown(true)} onMouseUp={() => setIsMouseDown(false)} onDragLeave={() => setIsMouseDown(false)}>
                        <BsFilter style={{ fontSize: '20px' }} />
                        <div className={`text-[13px] leading-3`}>Filters</div>
                    </div>
                    {filters.showFilters && (
                        <div className="">
                            <div className="flex gap-3 p-1 text-[13px] w-full max-w-full font-semibold">
                                <div id="Te gjitha" className={`h-8 flex justify-center items-center p-2 px-4 text-nowrap border text-[#344054] ${filters.selectedFilter === '' ? 'border-primary bg-primary text-white' : 'border-gray-200 hover:bg-gray-100'} rounded-[12px] leading-3 cursor-pointer`} onClick={filters.handleFilterSelect}>Te gjitha</div>
                                {filters.categories.map(option => (
                                    option === 'Approved' ? (
                                        <>
                                            <div className="relative">
                                                <div id={option} className={`h-8 flex gap-2 justify-center items-center p-2 px-4 text-nowrap border text-[#344054] ${option === filters.selectedFilter ? 'border-primary bg-primary text-white' : 'border-gray-200 hover:bg-gray-100'} rounded-[12px] leading-3 cursor-pointer`} onClick={(e) => handleApprovedClick(e)}>
                                                    {option}
                                                    <div className=""><FaChevronDown fontSize={12} onClick={handleDropdown} /></div>
                                                </div>
                                                {isDropDownActive && (
                                                    <div className="absolute top-full mt-1 left-0 bg-white rounded-xl z-10 w-full border">
                                                        <div className={`h-8 flex items-center px-4 cursor-pointer hover:bg-gray-50 rounded-xl ${filters.pauseOnFilter === 'on' ? 'text-primary' : 'text-[#344054]'}`} onClick={filters.handlePauseOnFilter}>Pause on</div>
                                                        <div className={`h-8 flex items-center px-4 cursor-pointer hover:bg-gray-50 rounded-xl ${filters.pauseOnFilter === 'off' ? 'text-primary' : 'text-[#344054]'}`} onClick={filters.handlePauseOffFilter}>Pause off</div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="relative">
                                            <div id={option} className={`h-8 flex justify-center items-center p-2 px-4 text-nowrap border text-[#344054] ${option === filters.selectedFilter ? 'border-primary bg-primary text-white' : 'border-gray-200 hover:bg-gray-100'} rounded-[12px] leading-3 cursor-pointer`} onClick={filters.handleFilterSelect}>{option}</div>
                                            {option === 'Updated' && filters.updatedStatusCount > 0 && <div className="absolute -top-1 -right-1 w-4 h-4 rounded-full bg-red-600 text-white flex items-center justify-center text-[10px]">{filters.updatedStatusCount}</div>}
                                        </div>
                                    )))}
                                {filters.filterByCity && (
                                    <div>{filters.filterByCity}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {filters.setDateRange && (
                    <div className="flex flex-col text-sm">
                        <div className="relative flex items-center gap-4">
                            {'createdAt' in filters.params && <div onClick={filters.removeDateFilter} className="text-gray-500 hover:text-red-600 cursor-pointer" title="Clear date filter"><IoIosCloseCircleOutline fontSize={24} /></div>}
                            <div className="flex justify-between items-center gap-2 border border-gray-300 rounded-xl p-2 px-4 cursor-pointer leading-3" onClick={() => filters.handleIsDateRangeOpen(filters.isDateRangeOpen)}>
                                <div className="text-nowrap">{filters.dateBtnLabel}</div>
                                <div className="pointer-events-none">
                                    <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={16} /></span>
                                </div>
                            </div>
                            {selectedReceipts.receipts.length > 0 && (
                                <div className="flex gap-2 items-center">
                                    <div className="rounded-full p-1 text-red-600 border border-red-600 flex items-center justify-center hover:text-white hover:bg-red-600 cursor-pointer" title="Delete All" onClick={handleDeleteAllReceipts}>
                                        <MdOutlineDelete size={20} />
                                    </div>
                                </div>
                            )}
                        </div>
                        {filters.isDateRangeOpen && (
                            <div className="w-full relative">
                                <DateRangePicker
                                    ranges={filters.dateRange}
                                    onChange={filters.handleDateRangeChange}
                                    moveRangeOnFirstSelection={false}
                                    showSelectionPreview={false}
                                    staticRanges={[]}
                                    inputRanges={[]}
                                    className="absolute top-4 right-0 z-10 border border-gray-200"
                                    rangeColors={['var(--primary-color)']}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div >
    )
}