import { MdDone } from "react-icons/md";
import { RiUploadCloud2Line } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import DashboardHeader from "../DashboardHeader";
import { GoVerified } from "react-icons/go";
import { useEffect, useRef, useState } from "react";
import { TfiTrash } from "react-icons/tfi";
import { GoDownload } from "react-icons/go";
import { AiOutlineFilePdf } from "react-icons/ai";
import useAlertDispatchActions from "../useAlertDispatchActions";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alertSlice";
import { FiTrash2 } from "react-icons/fi";
import { useCreateUserMutation } from "../../store/services/userServices";
import { useNavigate } from "react-router";

export default function AddUser() {

    const { openModalWithTimeout } = useAlertDispatchActions()
    const [createUser, { isLoading, isSuccess, isError }] = useCreateUserMutation()
    const dispatch = useDispatch()

    const inputRef = useRef()

    const navigate = useNavigate()

    const [files, setFiles] = useState([])
    const [filesPreview, setFilesPreview] = useState([])
    const [userDetails, setUserDetails] = useState({
        name: "",
        email: "",
        phone_number: "",
        birthdate: "",
        zip_code: "",
        password: "",
        confirm_password: "",
        role: "user",
        isVerified: 0,
    },)

    const [errors, setErrors] = useState({})

    const handleBtnClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const validateUserDetails = () => {
        let errors = {}
        if (!userDetails.name) errors.name = "Name is required"
        if (!userDetails.email) errors.email = "Email is required"
        if (!userDetails.phone_number) errors.phone_number = "Phone number is required"
        if (!userDetails.birthdate) errors.birthdate = "Birthdate is required"
        if (!userDetails.zip_code) errors.zip_code = "Zip code is required"
        if (!userDetails.password) errors.password = "Password is required"
        if (userDetails.password.length > 0 && userDetails.password.length < 6) errors.passwordLength = 'Password should be longer than 6 characters'
        if (!userDetails.password) errors.confirm_password = "Please confirm your password"

        if (userDetails.password !== userDetails.confirm_password) errors.passwordsDontMatch = "Passwords don't match"

        setErrors(errors)
        if (Object.keys(errors).length > 1) {
            openModalWithTimeout({ text: 'Please fill out all the fields', type: 'error' })
        }
        if (Object.keys(errors).length === 1) {
            openModalWithTimeout({ text: Object.values(errors)[0], type: 'error' })
        }

        return Object.keys(errors).length === 0;
    }

    const handleAddUserSubmit = async (e) => {
        const userData = {
            name: userDetails.name,
            email: userDetails.email,
            password: userDetails.password,
            phone_number: userDetails.phone_number,
            // type: userDetails.type,
            birthdate: userDetails.birthdate,
            zip_code: userDetails.zip_code
            // isVerified: userDetails.isVerified,
        }
        if (validateUserDetails()) {
            try {
                const result = await createUser(userData).unwrap();
                console.log('User created:', result);
                navigate('/users')
                dispatch(openModalWithTimeout({ text: 'User successfully created', type: 'success' }, 3000))
            } catch (error) {
                console.error('Failed to create item:', error);
            }
        }
        else {
            console.log("errors")
        }
    }

    const buttons = [
        { label: 'Cancel', color: 'bg-white', border: 'border border-[#D0D5DD]', shadow: 'shadow-sm', icon: null, onClick: null, linkTo: '/users' },
        { label: 'Save', color: 'bg-primary text-white', border: 'border border-b-2 border-primary', shadow: 'shadow-md', icon: <MdDone />, onClick: handleAddUserSubmit, linkTo: null }
    ]

    const details = {
        title: 'Add User'
    }

    const handleUserFormChange = (e) => {
        if (e.target.name === 'user_type') {
            if (e.target.value === 'company') {
                const number = userDetails.phone_number
                const name = `${userDetails.first_name} ${userDetails.last_name}`
                setUserDetails({ ...userDetails, [e.target.name]: e.target.value, contact_number: number, company_name: name, phone_number: '', first_name: '', last_name: '' })
                return;
            }
            if (e.target.value === 'individual') {
                const number = userDetails.contact_number
                const name = userDetails.company_name
                setUserDetails({ ...userDetails, [e.target.name]: e.target.value, contact_number: '', company_name: '', phone_number: number, first_name: name.split()[0], last_name: name.split().pop() })
                return;
            }
        }
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
        if (e.target.value) {
            let updateErrors = { ...errors }
            delete updateErrors[e.target.name]
            setErrors(updateErrors)
        }
    }

    const handleUserFilesChange = (e) => {
        const hasImage = Array.from(e.target.files).filter(file => file.type.includes("image")) || []
        const hasPdf = Array.from(e.target.files).filter(file => file.type.includes("pdf")) || []
        if (Array.from(e.target.files).length + files.length > 2) {
            dispatch(alertActions.openModal({ text: 'Only up to 2 files can be uploaded (1 image and 1 pdf)', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (hasImage.length > 1) {
            dispatch(alertActions.openModal({ text: 'Only one image file can be uploaded', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (hasPdf.length > 1) {
            dispatch(alertActions.openModal({ text: 'Only one pdf file can be uploaded', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (files.some(file => file.type.includes("image") && hasImage.length > 0)) {
            dispatch(alertActions.openModal({ text: 'Only one image is allowed', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (files.some(file => file.type.includes("pdf") && hasPdf.length > 0)) {
            dispatch(alertActions.openModal({ text: 'Only one pdf file is allowed', type: 'error' }));
            inputRef.current.value = null
            return
        }
        setFiles([...files, ...Array.from(e.target.files)])
        inputRef.current.value = null
    }

    const handleClearFileInput = () => {
        inputRef.current.value = null
        setFiles([])
    }

    useEffect(() => {
        setFilesPreview(files.map((file) => {
            if (file.type.includes('image')) {
                return { isImage: true, path: URL.createObjectURL(file), name: file.name, uploadedTime: new Date().toLocaleString('en-GB') }
            }
            else {
                return { isImage: false, path: null, name: file.name, uploadedTime: new Date().toLocaleString('en-GB') }
            }
        }))
    }, [files])

    const handleRemoveFile = (index) => {
        inputRef.current.value = null
        const updatedFiles = [...files]
        updatedFiles.splice(index, 1)
        setFiles(updatedFiles)
    }

    return (
        <div>
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <div className="p-6 bg-[#F9FAFB] rounded-b-xl text-sm">
                <form className="font-medium" onSubmit={(e) => e.preventDefault()}>
                    <div className="flex gap-8 w-full">
                        <div className="w-full p-1">
                            <div>
                                <label htmlFor="name">Name & Surname</label>
                                <input type="text" id="name" name="name" value={userDetails.name} onChange={handleUserFormChange} className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.name ? 'border-red-400' : 'border-gray-300'}`} />
                            </div>
                            <div>
                                <label htmlFor="phone_number">Phone Number</label>
                                <input type="tel" id="phone_number" name="phone_number" value={userDetails.phone_number} onChange={handleUserFormChange} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.phone_number ? 'border-red-400' : 'border-gray-300'}`} />
                            </div>
                            <div className="w-full flex gap-4">
                                <div className="w-full">
                                    <label htmlFor="user_role">Role</label>
                                    <div className="relative w-full">
                                        <select name="user_role" id="user_role" onChange={handleUserFormChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={userDetails.user_role}>
                                            <option value="admin">Admin</option>
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-full">
                                    <label htmlFor="user_type">User Type</label>
                                    <div className="relative w-full">
                                        <select name="user_type" id="user_type" onChange={handleUserFormChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={userDetails.user_type} disabled>
                                            <option value="company">Company</option>
                                            <option value="individual">Individual</option>
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="w-full">
                                    <div className="w-full">
                                        <label htmlFor="verified">Status</label>
                                        <div className="relative w-full">
                                            <select disabled name="verified" id="verified" value={userDetails.isVerified} onChange={handleUserFormChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                                <option value={0}>Verified <span><GoVerified style={{ backgroundColor: 'red' }} /></span></option>
                                                <option value={1}>Unverified</option>
                                            </select>
                                            <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                                <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="w-full">
                                <div className="w-full">
                                    <label htmlFor="verified">Status</label>
                                    <div className="relative w-full">
                                        <select disabled name="verified" id="verified" value={userDetails.isVerified} onChange={handleUserFormChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                            <option value={0}>Verified <span><GoVerified style={{ backgroundColor: 'red' }} /></span></option>
                                            <option value={1}>Unverified</option>
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="w-full">
                                <div className="w-full">
                                    <label htmlFor="birthdate">Birthdate</label>
                                    <input type="date" id="birthdate" name="birthdate" value={userDetails.birthdate} onChange={handleUserFormChange} className={`block rounded-md border ${errors.birthdate ? 'border-red-400' : 'border-gray-300'} w-full p-4 my-3 mt-2`} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full p-1">
                            <div>
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" value={userDetails.email} onChange={handleUserFormChange} className={`block rounded-md border ${errors.email ? 'border-red-400' : 'border-gray-300'} w-full p-4 my-3 mt-2`} />
                            </div>
                            <div>
                                <label htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" value={userDetails.password} onChange={handleUserFormChange} className={`block rounded-md border ${errors.password || errors.passwordsDontMatch ? 'border-red-400' : 'border-gray-300'} w-full p-4 my-3 mt-2`} />
                            </div>
                            <div>
                                <label htmlFor="confirm_password">Confirm password</label>
                                <input type="password" id="confirm_password" name="confirm_password" value={userDetails.confirm_password} onChange={handleUserFormChange} className={`block rounded-md border ${errors.confirm_password || errors.passwordsDontMatch ? 'border-red-400' : 'border-gray-300'} w-full p-4 my-3 mt-2`} />
                            </div>
                            <div>
                                <label htmlFor="zip_code">Zip code</label>
                                <input type="text" id="zip_code" name="zip_code" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.zip_code ? 'border-red-400' : 'border-gray-300'}`} value={userDetails.zip_code} onChange={handleUserFormChange} />
                            </div>
                        </div>
                    </div>
                    <div className="p-1">
                        {files.length > 0 && (
                            <>
                                <h1 className="my-4 font-semibold text-gray-600">Dokumentet e ngarkuara</h1>
                                {filesPreview.map((preview, index) => (
                                    preview.isImage ? (
                                        <div key={index} className="mb-4">
                                            <div className="flex items-center gap-8">
                                                <div className="flex flex-col gap-1 w-[250px]">
                                                    <div className="font-bold">{preview.name}</div>
                                                    <div className="text-gray-400 text-sm">{preview.uploadedTime}</div>
                                                </div>
                                                <div className="w-32 h-16 rounded-xl">
                                                    <img src={`${preview.path}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                                </div>
                                                <div className="flex gap-4">
                                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={() => handleRemoveFile(index)} />
                                                    <GoDownload fontSize={20} title="Download" />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={index} id className="mb-4">
                                            <div className="flex items-center gap-8">
                                                <div className="flex flex-col gap-1 min-w-[250px]">
                                                    <div className="font-bold">{preview.name}</div>
                                                    <div className="text-gray-400 text-sm">{preview.uploadedTime}</div>
                                                </div>
                                                <div className="w-32 h-16 flex border border-gray-200 items-center justify-center rounded-xl">
                                                    <AiOutlineFilePdf color="red" fontSize={30} />
                                                </div>
                                                <div className="flex gap-4">
                                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={() => handleRemoveFile(index)} />
                                                    <GoDownload fontSize={20} title="Download" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </>
                        )}
                    </div>
                    <div className="p-1">
                        <input
                            id="files"
                            name="files"
                            type="file"
                            accept="image/pmg, image/jpg, image/jpeg, application/pdf"
                            multiple
                            className="hidden"
                            onChange={handleUserFilesChange}
                            ref={inputRef}
                        />
                        {files.length > 1 ? (
                            <div className="h-[280px] flex items-center w-full p-2 bg-[#FBFEFC] rounded-xl border-2 border-dashed border-green-300 flex justify-center">
                                <div className="my-4">
                                    <div className="rounded-full w-14 h-14 bg-[#E7F6EC] flex justify-center items-center mx-auto cursor-pointer">
                                        <div className="rounded-full w-6 h-6 flex justify-center items-center bg-green-600">
                                            <MdDone fontSize={16} color="white" />
                                        </div>
                                    </div>
                                    <div className="text-black my-2 text-center">
                                        <span className="font-semibold">Dokumentet u ngarkuan</span>
                                    </div>
                                    <div className="p-1 px-4 w-max rounded-lg text-gray-400 mx-auto cursor-pointer flex gap-2 items-center" onClick={handleClearFileInput}>
                                        <span><FiTrash2 fontSize={16} /></span>
                                        <span className="leading-3 font-semibold">Pastro ngarkimin</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <label htmlFor="files">
                                <div className="w-full p-2 bg-white rounded-xl border-2 border-dashed border-gray-200 flex justify-center cursor-pointer">
                                    <div className="my-4">
                                        <div className="rounded-full w-14 h-14 bg-gray-100 flex justify-center items-center mx-auto  cursor-pointer">
                                            <RiUploadCloud2Line fontSize={28} color="#555" />
                                        </div>
                                        <div className="text-appgray my-4 text-center">
                                            <span className="text-bloodred font-semibold cursor-pointer">Kliko per te ngarkuar</span> ose terheq dhe lesho
                                        </div>
                                        <div className="p-2 w-[350px] relative my-4 text-gray-400">
                                            <hr className="border border-[whitesmoke]" />
                                            <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-white px-3">
                                                OSE
                                            </div>
                                        </div>
                                        <div className="p-2 px-4 bg-bloodred w-max rounded-lg text-white mx-auto cursor-pointer" onClick={handleBtnClick}>Shfleto Dosjet</div>
                                    </div>
                                </div>
                            </label>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}