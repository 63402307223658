import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const furnishingTypeApi = createApi({
    reducerPath: 'furnishingTypeApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin/furnishing_types`, prepareHeaders: (headers) => {
            const token = localStorage.getItem('giz_superaccess');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getFurnishingTypes: build.query({
            query() {
                return {
                    url: '/getAll',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Furnishing Types']
        }),
        createFurnishingType: build.mutation({
            query: (data) => ({
                url: '/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Furnishing Types']
        }),
        deleteFurnishingType: build.mutation({
            query(id) {
                return {
                    url: `/delete/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Furnishing Types']
        }),
        updateFurnishingType: build.mutation({
            query({ id, data }) {
                return {
                    url: `/update/${id}`,
                    method: 'PUT',
                    body: data
                }
            },
            invalidatesTags: ['Furnishing Types']
        }),
    }),
});

export const {
    useLazyGetFurnishingTypesQuery,
    useGetFurnishingTypesQuery,
    useCreateFurnishingTypeMutation,
    useDeleteFurnishingTypeMutation,
    useUpdateFurnishingTypeMutation
} = furnishingTypeApi;