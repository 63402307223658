import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const brandApi = createApi({
    reducerPath: 'brandApi',
    baseQuery: (fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api` })),
    endpoints: (build) => ({
        getBrands: build.query({
            query() {
                return {
                    url: '/receipts/getAllBrandsApi',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Brands']
        }),
    }),
});

export const { useGetBrandsQuery } = brandApi;