import { MdDone } from "react-icons/md";
import { RiUploadCloud2Line } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import DashboardHeader from "../DashboardHeader";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../store/services/userServices";
import { useDispatch, useSelector } from "react-redux";
import { TfiTrash } from "react-icons/tfi";
import { GoDownload } from "react-icons/go";
import { AiOutlineFilePdf } from "react-icons/ai";
import { alertActions, openModalWithTimeout } from "../../store/alertSlice";
import { FiTrash2 } from "react-icons/fi";
import _ from 'lodash';

export const labelStyle = 'font-medium'

export const extractFileNameFromPath = (path) => {
    const normalizedPath = path.replace(/\\/g, '/');
    const fileName = normalizedPath.split('/').pop();
    return fileName
}

export default function EditUser() {

    const { id } = useParams()
    const { data, isLoading, isError, isSuccess } = useGetUserByIdQuery(id)
    const [oldFiles, setOldFiles] = useState([])
    const [newFiles, setNewFiles] = useState([])
    const [newFilesPreview, setNewFilesPreview] = useState([])
    const [updatedFiles, setUpdatedFiles] = useState([])
    const [errors, setErrors] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputRef = useRef()
    const [fullName, setFullName] = useState('')
    const [passwords, setPasswords] = useState({
        password: '',
        confirm_password: ''
    })

    const [updateUser] = useUpdateUserMutation();

    const [editedUser, setEditedUser] = useState({
        name: "",
        email: "",
        phone_number: "",
        birthdate: "",
        zip_code: "",
        password: "",
        confirm_password: "",
        type: "user",
        isVerified: 0,
    })

    function findChangedFields(data, editedUser) {
        const changedFields = {};

        // Iterate over keys of the data object
        Object.keys(data).forEach(key => {
            if (!_.isEqual(data[key], editedUser[key])) {
                changedFields[key] = { old: data[key], new: editedUser[key] };
            }
        });

        return changedFields;
    }

    useEffect(() => {
        const files = []
        if (data) {
            console.log({ data })
            setEditedUser({ ...data })
            if (data.image) {
                files.push(data.image)
            }
            if (data.business_certificate) {
                files.push(data.business_certificate)
            }
            setOldFiles(files)
            setFullName(`${data.first_name} ${data.last_name}`)
        }
    }, [data])

    const validateUserDetails = () => {
        let errors = {}
        if (!editedUser.name) errors.name = "Name is required"
        if (!editedUser.email) errors.email = "Email is required"
        if (!editedUser.phone_number && !editedUser.contact_number) errors.phone_number = "Phone number is required"
        if (!editedUser.birthdate) errors.birthdate = "Birthdate is required"
        if (!editedUser.zip_code) errors.birthdate = "Zip code is required"

        if (passwords.password.length > 0 && passwords.password.length < 6) errors.passwordLength = 'Password should be longer than 6 characters'
        if (passwords.password !== passwords.confirm_password) errors.passwordsDontMatch = "Passwords don't match"

        // if (updatedFiles.length < 2) errors.files = "You must upload two files, one image and one document"

        setErrors(errors)
        if (Object.keys(errors).length > 0) {
            dispatch(alertActions.openModal({ text: Object.values(errors)[0], type: 'error' }))
        }

        return Object.keys(errors).length === 0;
    }

    const handleEditUserSubmit = async (e) => {
        const userData = {
            name: editedUser.name,
            email: editedUser.email,
            phone_number: editedUser.phone_number,
            type: editedUser.type,
            birthdate: editedUser.birthdate,
            zip_code: editedUser.zip_code,
            ...(passwords.password.length > 0 && { password: passwords.password }),
            // isverified: parseInt(editedUser.isVerified),
        }
        if (validateUserDetails()) {
            const changedFields = findChangedFields(data, editedUser)

            let dataToBeSubmitted = Object.keys(changedFields).reduce((acc, key) => {
                acc[key] = changedFields[key].new;
                return acc;
            }, {});

            if (passwords.password.length > 0) {
                dataToBeSubmitted['password'] = passwords.password
            }

            try {
                const result = await updateUser({ id: id, data: dataToBeSubmitted }).unwrap();
                console.log('User updated:', result);
                navigate(-1)
                dispatch(openModalWithTimeout({ text: 'User successfully updated', type: 'success' }, 3000))
            } catch (error) {
                console.error('Failed to create item:', error);
            }
        }
    }

    const handleUserFilesChange = (e) => {
        const hasImage = Array.from(e.target.files).filter(file => file.type.includes("image")) || []
        const hasPdf = Array.from(e.target.files).filter(file => file.type.includes("pdf")) || []
        if (Array.from(e.target.files).length + oldFiles.length > 2) {
            dispatch(alertActions.openModal({ text: 'Only up to 2 files can be uploaded (1 image and 1 pdf)', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (hasImage.length > 1) {
            dispatch(alertActions.openModal({ text: 'Only one image file can be uploaded', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (hasPdf.length > 1) {
            dispatch(alertActions.openModal({ text: 'Only one pdf file can be uploaded', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (oldFiles.some(file => file.includes("images") && hasImage.length > 0)) {
            dispatch(alertActions.openModal({ text: 'Only one image is allowed', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (oldFiles.some(file => file.includes("certificates") && hasPdf.length > 0)) {
            dispatch(alertActions.openModal({ text: 'Only one pdf file is allowed', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (newFiles.some(file => file.type.includes("image") && hasImage.length > 0)) {
            dispatch(alertActions.openModal({ text: 'Only one image is allowed', type: 'error' }));
            inputRef.current.value = null
            return
        }
        if (newFiles.some(file => file.type.includes("pdf") && hasPdf.length > 0)) {
            dispatch(alertActions.openModal({ text: 'Only one pdf file is allowed', type: 'error' }));
            inputRef.current.value = null
            return
        }
        setNewFiles([...newFiles, ...Array.from(e.target.files)])
        inputRef.current.value = null
    }

    useEffect(() => {
        setNewFilesPreview(newFiles.map((file) => {
            if (file.type.includes('image')) {
                return { isImage: true, path: URL.createObjectURL(file), name: file.name, uploadedTime: new Date().toLocaleString('en-GB') }
            }
            else {
                return { isImage: false, path: null, name: file.name, uploadedTime: new Date().toLocaleString('en-GB') }
            }
        }))
    }, [newFiles])

    useEffect(() => {
        setUpdatedFiles([...newFiles, ...oldFiles])
    }, [newFiles, oldFiles])

    useEffect(() => {
        console.log({ editedUser })
    }, [editedUser])

    const handleRemoveNewFile = (index) => {
        inputRef.current.value = null
        const updatedFiles = [...newFiles]
        updatedFiles.splice(index, 1)
        setNewFiles(updatedFiles)
    }

    const handleRemoveOldFile = (index) => {
        inputRef.current.value = null
        const updatedFiles = [...oldFiles]
        updatedFiles.splice(index, 1)
        setOldFiles(updatedFiles)
        setEditedUser({ ...editedUser, business_certificate: null })
    }

    const handleClearFileInput = () => { }

    const handleEditUserChange = (e) => {
        if (e.target.name === 'user_type') {
            if (e.target.value === 'company') {
                const number = editedUser.phone_number
                const name = `${editedUser.first_name} ${editedUser.last_name}`
                setEditedUser({ ...editedUser, [e.target.name]: e.target.value, contact_number: number, company_name: name, phone_number: '', first_name: '', last_name: '' })
                return;
            }
            if (e.target.value === 'individual') {
                const number = editedUser.contact_number
                const name = editedUser.company_name
                setEditedUser({
                    ...editedUser,
                    [e.target.name]: e.target.value,
                    contact_number: '',
                    company_name: '',
                    company_number: '',
                    address: '',
                    website: '',
                    phone_number: number,
                    first_name: name.split(" ")[0],
                    last_name: name.split(" ").pop(),
                })
                setFullName(`${name.split(" ")[0]} ${name.split(" ").pop()}`)
                return;
            }
        }
        setEditedUser({ ...editedUser, [e.target.name]: e.target.value })
    }

    const handlePasswordChange = (e) => {
        setPasswords({ ...passwords, [e.target.name]: e.target.value })
    }

    const handleBtnClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const buttons = [
        { label: 'Cancel', color: 'bg-white', border: 'border border-[#D0D5DD]', shadow: 'shadow-sm', icon: null, onClick: () => navigate(-1), linkTo: null },
        { label: 'Save', color: 'bg-primary text-white', border: 'border border-b-2 border-primary', shadow: 'shadow-md', icon: <MdDone />, onClick: handleEditUserSubmit, linkTo: null }
    ]

    const details = {
        title: 'Edit User'
    };

    const handleDownloadFile = () => {
        const url = `${process.env.REACT_APP_BACKEND_HOST}/${editedUser.business_certificate}`;
        window.open(url, "_blank");
    };

    return (
        <div>
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <div className="p-6 bg-[#F9FAFB] rounded-b-xl text-sm">
                <form className="font-medium">
                    <div className="flex gap-8 w-full">
                        <div className="w-full p-1">
                            <div>
                                <label className={labelStyle} htmlFor="name">Name & Surname</label>
                                <input type="text" name="name" id="name" value={editedUser.name} onChange={handleEditUserChange} className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.name ? 'border-red-400' : 'border-gray-300'}`} />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="phone_number">Phone Number</label>
                                <input type="tel" id="phone_number" name="phone_number" value={editedUser.phone_number} onChange={handleEditUserChange} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.phone_number ? 'border-red-400' : 'border-gray-300'}`} />
                            </div>
                            <div className="w-full flex gap-4">
                                <div className="w-full">
                                    <label className={labelStyle} htmlFor="type">Role</label>
                                    <div className="relative w-full">
                                        <select name="type" id="type" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={editedUser.role} onChange={handleEditUserChange}>
                                            <option value="admin">Admin</option>
                                            <option value="user">User</option>
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-full">
                                    <label className={labelStyle} htmlFor="user_type">User Type</label>
                                    <div className="relative w-full">
                                        <select name="type" id="type" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={editedUser.type} onChange={handleEditUserChange}>
                                            <option value="company">Company</option>
                                            <option value="individual">Individual</option>
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="w-full">
                                    <label htmlFor="isVerified">Status</label>
                                    <div className="relative w-full">
                                        <select disabled name="isVerified" id="isVerified" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={editedUser.isVerified} onChange={handleEditUserChange}>
                                            <option value={1}>Verified</option>
                                            <option value={0}>Unverified</option>
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="w-full">
                                    <label htmlFor="birthdate">Birthdate</label>
                                    <input type="date" id="birthdate" name="birthdate" value={editedUser.birthdate && new Date(editedUser?.birthdate).toISOString().split('T')[0]} onChange={handleEditUserChange} className={`block rounded-md border ${errors.birthdate ? 'border-red-400' : 'border-gray-300'} w-full p-4 my-3 mt-2`} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full p-1">
                            <div>
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" value={editedUser.email} onChange={handleEditUserChange} className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.email ? 'border-red-400' : 'border-gray-300'}`} />
                            </div>
                            <div>
                                <label htmlFor="password">New password</label>
                                <input type="password" id="password" name="password" value={passwords.password} onChange={handlePasswordChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2" />
                            </div>
                            <div>
                                <label htmlFor="confirm_password">Confirm new password</label>
                                <input type="password" id="confirm_password" name="confirm_password" value={passwords.confirm_password} onChange={handlePasswordChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2" />
                            </div>
                            <div>
                                <label htmlFor="zip_code">Zip code</label>
                                <input type="text" id="zip_code" name="zip_code" value={editedUser.zip_code} onChange={handleEditUserChange} className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 ${errors.zip_code ? 'border-red-400' : 'border-gray-300'}`} />
                            </div>
                        </div>
                    </div>
                    <div className="p-1">
                        {oldFiles.length > 0 && (
                            <>
                                <h1 className="my-4 font-semibold text-gray-600">Dokumentet e ngarkuara</h1>
                                {oldFiles.map((file, index) => (
                                    file.includes('images') ? (
                                        <div key={index} className="mb-4">
                                            <div className="flex items-center gap-8">
                                                <div className="flex flex-col gap-1 w-[250px]">
                                                    <div className="font-bold">{extractFileNameFromPath(file)}</div>
                                                    <div className="text-gray-400 text-sm">uploaded time</div>
                                                </div>
                                                <div className="w-32 h-16 rounded-xl">
                                                    <img src={`${process.env.REACT_APP_BACKEND_HOST}/${file}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                                </div>
                                                <div className="flex gap-4">
                                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={() => handleRemoveOldFile(index)} />
                                                    <GoDownload fontSize={20} title="Download" cursor="pointer" onClick={handleDownloadFile} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={index} id className="mb-4">
                                            <div className="flex items-center gap-8">
                                                <div className="flex flex-col gap-1 w-[250px]">
                                                    <div className="font-bold">{extractFileNameFromPath(file)}</div>
                                                    <div className="text-gray-400 text-sm">date</div>
                                                </div>
                                                <div className="w-32 h-16 flex border border-gray-200 items-center justify-center rounded-xl">
                                                    <AiOutlineFilePdf color="red" fontSize={30} />
                                                </div>
                                                <div className="flex gap-4">
                                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={() => handleRemoveOldFile(index)} />
                                                    <GoDownload fontSize={20} title="Download" cursor="pointer" onClick={handleDownloadFile} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </>
                        )}
                        {newFiles.length > 0 && (
                            <>
                                {newFilesPreview.map((preview, index) => (
                                    preview.isImage ? (
                                        <div key={index} className="mb-4">
                                            <div className="flex items-center gap-8">
                                                <div className="flex flex-col gap-1 w-[250px]">
                                                    <div className="font-bold">{preview.name}</div>
                                                    <div className="text-gray-400 text-sm">{preview.uploadedTime}</div>
                                                </div>
                                                <div className="w-32 h-16 rounded-xl">
                                                    <img src={`${preview.path}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                                </div>
                                                <div className="flex gap-4">
                                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={() => handleRemoveNewFile(index)} />
                                                    <GoDownload fontSize={20} title="Download" />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={index} id className="mb-4">
                                            <div className="flex items-center gap-8">
                                                <div className="flex flex-col gap-1 w-[250px]">
                                                    <div className="font-bold">{preview.name}</div>
                                                    <div className="text-gray-400 text-sm">{preview.uploadedTime}</div>
                                                </div>
                                                <div className="w-32 h-16 flex border border-gray-200 items-center justify-center rounded-xl">
                                                    <AiOutlineFilePdf color="red" fontSize={30} />
                                                </div>
                                                <div className="flex gap-4">
                                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={() => handleRemoveNewFile(index)} />
                                                    <GoDownload fontSize={20} title="Download" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </>
                        )}
                        <input
                            id="files"
                            name="files"
                            type="file"
                            accept="image/pmg, image/jpg, image/jpeg, application/pdf"
                            multiple
                            className="hidden"
                            onChange={handleUserFilesChange}
                            ref={inputRef}
                        />
                        {updatedFiles.length > 1 ? (
                            <div className="h-[280px] flex items-center w-full p-2 bg-[#FBFEFC] rounded-xl border-2 border-dashed border-green-300 flex justify-center">
                                <div className="my-4">
                                    <div className="rounded-full w-14 h-14 bg-[#E7F6EC] flex justify-center items-center mx-auto cursor-pointer">
                                        <div className="rounded-full w-6 h-6 flex justify-center items-center bg-green-600">
                                            <MdDone fontSize={16} color="white" />
                                        </div>
                                    </div>
                                    <div className="text-black my-2 text-center">
                                        <span className="font-semibold">Dokumentet u ngarkuan</span>
                                    </div>
                                    <div className="p-1 px-4 w-max rounded-lg text-gray-400 mx-auto cursor-pointer flex gap-2 items-center" onClick={handleClearFileInput}>
                                        <span><FiTrash2 fontSize={16} /></span>
                                        <span className="leading-3 font-semibold">Pastro ngarkimin</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <label htmlFor="files">
                                <div className="w-full p-2 bg-white rounded-xl border-2 border-dashed border-gray-200 flex justify-center cursor-pointer">
                                    <div className="my-4">
                                        <div className="rounded-full w-14 h-14 bg-gray-100 flex justify-center items-center mx-auto  cursor-pointer">
                                            <RiUploadCloud2Line fontSize={28} color="#555" />
                                        </div>
                                        <div className="text-appgray my-4 text-center">
                                            <span className="text-bloodred font-semibold cursor-pointer">Kliko per te ngarkuar</span> ose terheq dhe lesho
                                        </div>
                                        <div className="p-2 w-[350px] relative my-4 text-gray-400">
                                            <hr className="border border-[whitesmoke]" />
                                            <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-white px-3">
                                                OSE
                                            </div>
                                        </div>
                                        <div className="p-2 px-4 bg-bloodred w-max rounded-lg text-white mx-auto cursor-pointer" onClick={handleBtnClick}>Shfleto Dosjet</div>
                                    </div>
                                </div>
                            </label>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}