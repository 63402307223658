import { IoMdEye } from "react-icons/io";
import DashboardHeader from "../DashboardHeader";
import { MdDone, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { labelStyle } from "../users/EditUser";
import { LuTrash2 } from "react-icons/lu";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { characteristicsModalActions } from "../../store/characteristicsModalSlice";
import { openModalWithTimeout } from "../../store/alertSlice";
import { useGetPropertyTypesQuery } from "../../store/services/propertyTypeServices";
import { useGetHeatingTypesQuery } from "../../store/services/heatingTypeServices";
import { useGetCharacteristicsQuery } from "../../store/services/characteristicsServices";
import { AiOutlineFilePdf } from "react-icons/ai";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { extractFileNameFromPath } from "../users/EditUser";
import { RiUploadCloud2Line } from "react-icons/ri";
import _ from 'lodash';
import { GrRotateRight } from "react-icons/gr";
import { FaCheckCircle } from "react-icons/fa";
import { BsTrash3Fill } from "react-icons/bs";
import { useGetFurnishingTypesQuery } from "../../store/services/furnishingTypeServices";
import { useGetReceiptByIdQuery } from "../../store/services/receiptsServices";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";

const receipt = {
    id: '',
    user: {
        userName: '',
    },
    name: '',
    created_at: '',
    body_type: '',
    brand: '',
    subBrand: '',
    fuel: '',
    transmission: '',
    transmission_name: '',
    km: '',
    weight: '',
    location: '',
    price: '',
    tvsh: '',
    customs: '',
    year: '',
    production_month: '',
    ps: '',
    seats: '',
    exteriorColor: '',
    exterior_color_name: '',
    cubic: '',
    description: ``,
    status: '',
    serie: '',
    registration_date: '',
    images: [
        {
            path: '',
            isCoverImage: true
        },
    ]
}

export default function EditReceipt() {

    // const [triggerGetBodyTypes, { data: bodyTypesData, error: isBodyTypesError, isLoading: isBodyTypesLoading }] = useLazyGetBodyTypesQuery();
    const { data: propertyTypesData } = useGetPropertyTypesQuery()
    const { data: heatingTypesData } = useGetHeatingTypesQuery()
    const { data: characteristicsData } = useGetCharacteristicsQuery()
    const { data: furnishingTypesData } = useGetFurnishingTypesQuery()
    // const { data: brandsData } = useGetBrandsQuery()

    const { id } = useParams()

    const [editedReceipt, setEditedReceipt] = useState(receipt)
    const [imagesToDelete, setImagesToDelete] = useState([])
    const navigate = useNavigate()
    const [mainImageIndex, setMainImageIndex] = useState(null)
    const [characteristicsDropdownOpen, setCharacteristicsDropdownOpen] = useState(false)
    const [heatingTypesDropdownOpen, setHeatingTypesDropdownOpen] = useState(false)
    const [furnishingTypesDropdown, setFurnishingTypesDropdown] = useState(false)
    const [newFiles, setNewFiles] = useState({})
    const inputRef = useRef()

    const { data, isLoading, isError, IsSuccess } = useGetReceiptByIdQuery(id)

    useEffect(() => {
        console.log({ propertyTypesData })
    }, [propertyTypesData])

    useEffect(() => {
        console.log({ editedReceipt })
    }, [editedReceipt])


    const dispatch = useDispatch()

    const tvshRef = useRef()

    function findChangedFields(data, editedReceipt) {
        const changedFields = {};

        // Iterate over keys of the data object
        Object.keys(data).forEach(key => {
            if (!_.isEqual(data[key], editedReceipt[key])) {
                changedFields[key] = { old: data[key], new: editedReceipt[key] };
            }
        });

        return changedFields;
    }

    // const handleTriggerBodyTypes = () => {
    //     triggerGetBodyTypes();
    // }

    useEffect(() => {
        if (data) {
            setEditedReceipt({ ...data, customs: (data.customs === 'true') })
            // setAccessories(JSON.parse(data.accessories))
            // if (brandsData) {
            //     setBrandLogo(brandsData.filter(brand => brand.name === data.brand))
            // }
        }
        window.scrollTo(0, 0)
    }, [data])

    const handleEditReceiptSubmit = async (e) => {
        e.preventDefault();
        const editedReceiptData = {
            ...editedReceipt,
            imagesToDelete: JSON.stringify(imagesToDelete),
            imageIndex: mainImageIndex
        }
        const changedFields = findChangedFields(data, editedReceipt)
        let dataToBeSubmitted = Object.keys(changedFields).reduce((acc, key) => {
            acc[key] = changedFields[key].new;
            return acc;
        }, {});
        if (imagesToDelete.length > 0) {
            dataToBeSubmitted['imagesToDelete'] = imagesToDelete
        }
        if (Object.keys(changedFields).includes("heating_types")) {
            const heating_ids = heatingTypesData
                .filter(heating_type => editedReceipt.heating_types.includes(heating_type.type))
                .map(heating_type => heating_type.id);

            dataToBeSubmitted['heating_ids'] = heating_ids.join(",")
        }
        if (Object.keys(changedFields).includes("characteristics")) {
            const character_ids = characteristicsData
                .filter(characteristic => editedReceipt.characteristics.includes(characteristic.name))
                .map(characteristic => characteristic.id);

            dataToBeSubmitted['characteristics'] = character_ids.join(",")
        }
        if (Object.keys(changedFields).includes("images")) {
            dataToBeSubmitted['orderedImages'] = editedReceipt.images
        }
        if (Object.keys(changedFields).includes("furnishing_types")) {
            const furnishing_ids = furnishingTypesData
                .filter(furnishing_type => editedReceipt.furnishing_types.includes(furnishing_type.type))
                .map(furnishing_type => furnishing_type.id);

            dataToBeSubmitted['furnishing_ids'] = furnishing_ids.join(",")
        }
        if (Object.keys(changedFields).includes("property_type")) {
            const property_id = propertyTypesData
                .filter(property_type => editedReceipt.property_type === property_type.type)
                .map(property_type => property_type.id);

            dataToBeSubmitted['property_id'] = property_id
        }
        try {
            // console.log({ dataToBeSubmitted })
            // const response = await updateReceipt({ id: id, data: dataToBeSubmitted }).unwrap();
            // navigate(-1)
            // dispatch(openModalWithTimeout({ text: 'Receipt updated successfully', type: 'success' }))
        }
        catch (error) {
            console.error('Failed to update item:', error);
        }
    }

    const details = {
        title: 'Receipt',
    }

    const buttons = [
    ]

    const handleEditReceiptChange = (e) => {
        if (e.target.id === 'customs') {
            setEditedReceipt({ ...editedReceipt, customs: e.target.checked })
            return;
        }
        setEditedReceipt({ ...editedReceipt, [e.target.name]: e.target.value })
    }

    const handleMainImage = (index) => {
        let updatedImages = [...editedReceipt?.images].map(image => {
            return { ...image, is_main: 0 }
        })
        updatedImages[index] = { ...updatedImages[index], is_main: 1 }
        const [selectedImage] = updatedImages.splice(index, 1);
        updatedImages.unshift(selectedImage);
        setEditedReceipt({ ...editedReceipt, images: updatedImages })
        setMainImageIndex(index)
    }

    useEffect(() => {
        console.log({ imagesToDelete })
    }, [imagesToDelete])

    const handleDeletePhoto = (index) => {
        let updatedImages = [...editedReceipt?.images]
        let imageToDelete = updatedImages[index]
        if (imageToDelete.is_main === 1) {
            dispatch(openModalWithTimeout({ text: 'You cannot delete the main image', type: 'error' }))
            return;
        }
        if (!updatedImages.some(image => image.is_main === 1) && index === 0) {
            dispatch(openModalWithTimeout({ text: 'You cannot delete the main image', type: 'error' }))
            return;
        }
        updatedImages.splice(index, 1);
        setEditedReceipt({ ...editedReceipt, images: updatedImages })
        setImagesToDelete([...imagesToDelete, imageToDelete.id])
        if (index < mainImageIndex) {
            const updatedIndex = mainImageIndex - 1;
            setMainImageIndex(updatedIndex)
        }
    }

    const handleHeatingTypeClick = (e) => {
        const heating_type = e.target.getAttribute('value')
        const updatedReceipt = { ...editedReceipt }
        if (updatedReceipt.heating_types.includes(heating_type)) {
            setEditedReceipt({ ...editedReceipt, heating_types: updatedReceipt.heating_types.filter(type => type !== heating_type) })
        }
        else {
            updatedReceipt.heating_types = [...updatedReceipt.heating_types, heating_type]
            setEditedReceipt({ ...editedReceipt, heating_types: updatedReceipt.heating_types })
        }
    }

    const handleCharacteristicsClick = (e) => {
        const name = e.target.getAttribute('value')
        const updatedReceipt = { ...editedReceipt }
        if (updatedReceipt.characteristics.includes(name)) {
            setEditedReceipt({ ...editedReceipt, characteristics: updatedReceipt.characteristics.filter(cname => cname !== name) })
        }
        else {
            updatedReceipt.characteristics = [...updatedReceipt.characteristics, name]
            setEditedReceipt({ ...editedReceipt, characteristics: updatedReceipt.characteristics })
        }
    }

    const handleFurnishingTypeClick = (e) => {
        const name = e.target.getAttribute('value')
        const updatedReceipt = { ...editedReceipt }
        if (updatedReceipt.furnishing_types.includes(name)) {
            setEditedReceipt({ ...editedReceipt, furnishing_types: updatedReceipt.furnishing_types.filter(fname => fname !== name) })
        }
        else {
            updatedReceipt.furnishing_types = [...updatedReceipt.furnishing_types, name]
            setEditedReceipt({ ...editedReceipt, furnishing_types: updatedReceipt.furnishing_types })
        }
    }

    const handleUserFilesChange = (e) => {
        setNewFiles(Array.from(e.target.files))
        inputRef.current.value = null
    }

    const handleDownloadFile = () => {
        const url = `${process.env.REACT_APP_BACKEND_HOST}/${editedReceipt.pdf_path}`;
        window.open(url, "_blank");
    };

    const handleDocumentDelete = () => {
        setNewFiles({})
        setEditedReceipt({ ...editedReceipt, pdf_path: null })
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === 0) {
            handleMainImage(result.source.index)
            return;
        }
        if (result.source.index === 0) {
            dispatch(openModalWithTimeout({ text: 'You cannot move the main image', type: 'error' }))
            return;
        }
        const updatedImages = Array.from(editedReceipt.images)
        const [reorderedItem] = updatedImages.splice(result.source.index, 1)
        updatedImages.splice(result.destination.index, 0, reorderedItem);

        setEditedReceipt({ ...editedReceipt, images: updatedImages })
    }

    const handlePhotoRotation = (index) => {
        let updatedImages = [...editedReceipt?.images]
        let imageToDelete = updatedImages[index]
        console.log(imageToDelete.rotate + 90)
        let updatedImageToDelete = {
            ...imageToDelete,
            rotate: imageToDelete.rotate ? imageToDelete.rotate + 90 : 90
        };
        updatedImages[index] = updatedImageToDelete
        setEditedReceipt({ ...editedReceipt, images: updatedImages })
    }

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();

        return (
            <div className="tools flex justify-center gap-4 my-4">
                <CiCirclePlus size={30} onClick={() => zoomIn()} />
                <CiCircleMinus size={30} onClick={() => zoomOut()} />
                {/* <button onClick={() => resetTransform()} className="w-8 h-8 rounded-full text-xl flex items-center justify-center border" /> */}
            </div>
        );
    };

    return (
        <>
            <DashboardHeader details={details} buttons={buttons} />
            <div className="p-6 w-full h-full text-sm bg-[#F9FAFB] rounded-b-xl">
                <div className="flex w-full gap-6">
                    <div className="w-1/2 flex justify-center">
                        <div className="w-1/2">
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                    <>
                                        <TransformComponent>
                                            <img src="/pics/download.jpeg" alt="img" className="w-full" />
                                        </TransformComponent>
                                        <Controls />
                                    </>
                                )}
                            </TransformWrapper>
                        </div>
                    </div>
                    <div className="w-1/2 h-full flex flex-col justify-between">
                        <div className="w-1/2">
                            <label className={labelStyle} htmlFor="vat_number">Numri fiskal</label>
                            <input type="tel" id="vat_number" name="vat_number" value="87293980" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 disabled:bg-white`} disabled />
                        </div>
                        <div className="w-1/2">
                            <label className={labelStyle} htmlFor="company">Emri i kompanise</label>
                            <input type="text" id="company" name="company" value="Viva Fresh" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 disabled:bg-white`} disabled />
                        </div>
                        <div className="w-1/2">
                            <label className={labelStyle} htmlFor="address">Adresa</label>
                            <input type="text" id="address" name="address" value="Ahmet Krasniqi, Prishtine" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 disabled:bg-white`} disabled />
                        </div>
                        <div className="w-1/2">
                            <label className={labelStyle} htmlFor="vat_amount">TVSH</label>
                            <input type="tel" id="vat_amount" name="vat_amount" value="0.01" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 disabled:bg-white`} disabled />
                        </div>
                        <div className="w-1/2">
                            <label className={labelStyle} htmlFor="total_amount">Shuma totale</label>
                            <input type="tel" id="total_amount" name="total_amount" value="2,85" className={`block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 disabled:bg-white`} disabled />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}