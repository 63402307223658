import PrizesListItem from "./PrizesListItem"

export default function PrizesList({ prizes }) {
    return (
        <>
            {prizes.slice(0, 10).map(prize => (
                <PrizesListItem prize={prize} />
            ))}
        </>
    )
}