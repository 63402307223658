import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("giz_superaccess");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {

    const originalRequest = error.config;

    if (error.response.status === 403) {
      try {
        console.log("403 error")
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/auth/refresh-token`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("giz_superrefresh")}`,
            },
          }
        );

        const newAccessToken = data.access_token;

        localStorage.setItem("giz_superaccess", newAccessToken);

        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        if (refreshError.status = 403) {
          window.location.href = "/login";
          localStorage.removeItem("giz_superaccess");
          localStorage.removeItem("giz_superrefresh");

        }
      }
    }

    // reject with error if response status is not 403
    return Promise.reject(error);
  }
);

export default axiosInstance;
